exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-automation-tools-js": () => import("./../../../src/templates/automation-tools.js" /* webpackChunkName: "component---src-templates-automation-tools-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-career-subpage-js": () => import("./../../../src/templates/career-subpage.js" /* webpackChunkName: "component---src-templates-career-subpage-js" */),
  "component---src-templates-careers-page-js": () => import("./../../../src/templates/careers-page.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-cintel-js": () => import("./../../../src/templates/cintel.js" /* webpackChunkName: "component---src-templates-cintel-js" */),
  "component---src-templates-commercial-analytics-js": () => import("./../../../src/templates/commercial-analytics.js" /* webpackChunkName: "component---src-templates-commercial-analytics-js" */),
  "component---src-templates-company-page-js": () => import("./../../../src/templates/company-page.js" /* webpackChunkName: "component---src-templates-company-page-js" */),
  "component---src-templates-competitive-intelligence-js": () => import("./../../../src/templates/competitive-intelligence.js" /* webpackChunkName: "component---src-templates-competitive-intelligence-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-custom-application-js": () => import("./../../../src/templates/custom-application.js" /* webpackChunkName: "component---src-templates-custom-application-js" */),
  "component---src-templates-data-management-js": () => import("./../../../src/templates/data-management.js" /* webpackChunkName: "component---src-templates-data-management-js" */),
  "component---src-templates-forecasting-js": () => import("./../../../src/templates/forecasting.js" /* webpackChunkName: "component---src-templates-forecasting-js" */),
  "component---src-templates-heor-js": () => import("./../../../src/templates/heor.js" /* webpackChunkName: "component---src-templates-heor-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-intellisight-js": () => import("./../../../src/templates/intellisight.js" /* webpackChunkName: "component---src-templates-intellisight-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/News.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-patient-analytics-js": () => import("./../../../src/templates/patient-analytics.js" /* webpackChunkName: "component---src-templates-patient-analytics-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-reports-js": () => import("./../../../src/templates/reports.js" /* webpackChunkName: "component---src-templates-reports-js" */),
  "component---src-templates-resources-page-js": () => import("./../../../src/templates/resources-page.js" /* webpackChunkName: "component---src-templates-resources-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-sub-services-js": () => import("./../../../src/templates/sub-services.js" /* webpackChunkName: "component---src-templates-sub-services-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-the-role-of-btk-inhibitors-in-ms-and-et-js": () => import("./../../../src/templates/the-role-of-btk-inhibitors-in-ms-and-et.js" /* webpackChunkName: "component---src-templates-the-role-of-btk-inhibitors-in-ms-and-et-js" */),
  "component---src-templates-therapy-js": () => import("./../../../src/templates/therapy.js" /* webpackChunkName: "component---src-templates-therapy-js" */),
  "component---src-templates-website-data-js": () => import("./../../../src/templates/website-data.js" /* webpackChunkName: "component---src-templates-website-data-js" */)
}

